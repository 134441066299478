<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      rounded
      min-height="100"
      class="my-4"
    >
      <v-row no-gutters>
        <v-col cols="1">
          <v-card
            class="rounded-r-0"
            flat
            min-height="100"
            color="success"
          >
            <v-card-text
              class="text-center"
            >
              <v-icon
                color="white"
                size="30"
                class="center-item"
              >
                mdi-check-circle-outline
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card
            dark
            flat
            height="60"
            class="rounded-l-0"
          >
            <v-card-text
              class="black--text mt-n2"
            >
              {{ $t('common|invitation') }} {{ translateInvitationStatus(invitation.status) }}
            </v-card-text>
            <v-card-text
              class="black--text mt-n8"
            >
              <span
                class="font-weight-bold"
                style="font-size: 14px"
              >
                {{ $t('invitations|accepted_connection') }}
                <router-link
                  v-if="account.accountType === 'individual' || account.accountType === 'company'"
                  to="/professionals"
                >
                  {{ invitation.senderEmail }}
                </router-link>
                <router-link
                  v-if="account.accountType === 'lawyer'"
                  to="/clients"
                >
                  {{ invitation.senderEmail }}
                </router-link>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="1">
          <v-icon
            style="position: absolute; top: 10px; right: 15px;"
            @click="$emit('remove-accepted-invitation', invitation)"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    invitation: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  methods: {
    translateInvitationStatus (input) {
      if (input === 'accepted') return this.$t('common|accepted')
      else return this.$t('common|rejected')
    }
  }
}
</script>

<style scoped>
  .center-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
