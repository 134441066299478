<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      rounded
      min-height="100"
      class="my-4"
    >
      <v-row
        no-gutters
      >
        <v-col cols="1">
          <v-card
            class="rounded-r-0"
            flat
            min-height="100"
            color="error"
          >
            <v-card-text
              class="text-center"
            >
              <v-icon
                color="white"
                size="28"
                class="center-item"
              >
                mdi-alert-octagon-outline
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card
            flat
            height="60"
            class="rounded-l-0"
          >
            <v-card-text
              class="black--text mt-n2"
            >
              {{ $t('common|invitation') }} {{ translateInvitationStatus(invitation.status) }}
            </v-card-text>
            <v-card-text
              class="black--text mt-n8"
            >
              <span
                class="font-weight-bold"
                style="font-size: 15px"
              >
                {{ invitation.recipientEmail }} {{ $t('expressions|rejected_the_invitation') }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="1">
          <v-icon
            style="position: absolute; top: 10px; right: 15px;"
            @click="$emit('remove-rejected-invitation-sent', invitation)"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  props: {
    invitation: {
      type: Object,
      default: null
    }
  },
  methods: {
    translateInvitationStatus (input) {
      if (input === 'accepted') return this.$t('common|accepted')
      else return this.checkGrammar(this.$t('common|rejected'))
    },
    checkGrammar (input) {
      if (input === 'отхвърлени') return 'отхвърлена'
      else return input
    }
  }
}
</script>

<style scoped>
  .center-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
