<template>
  <v-card
    width="100%"
    flat
    color="lightBackground"
    :style="`height: ${($vuetify.breakpoint.height - 145).toString() + 'px'}; overflow-y: auto; overflow-x: hidden;`"
  >
    <v-row
      align="center"
      justify="center"
    >
      <!-- ALL NOTIFICATIONS -->
      <v-col
        v-if="isAllNotificationsTab"
        cols="8"
      >
        <div v-if="allNotifications.length">
          <div
            v-for="n in allNotifications"
            :key="n._id"
            class="mt-8"
          >
            <NotificationsCard
              :notification="n"
              @delete-notification="deleteNotification"
              @open-document="openDocument"
              @mark-as-read="onMarkAsRead"
              @navigate-to-item="onNavigateToItem"
              @open-info-dialog="$emit('open-info-dialog', n)"
            />
          </div>
        </div>
        <div
          v-else
          class="center"
        >
          <v-icon
            color="primary"
          >
            mdi-bell-outline
          </v-icon>
          <span class="ml-2">{{ $t('notifications|no_current_notifications') }}</span>
        </div>
      </v-col>

      <!-- INVITATIONS -->
      <v-col
        v-if="isInvitationsTab"
        cols="10"
      >
        <v-list
          expand
          style="width: 100%;"
          class="mt-6"
          color="lightBackground"
        >
          <v-list-group
            :value="!!allInvitations.length"
            prepend-icon="mdi-inbox"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t('invitations|invitations_received') }}</v-list-item-title>
            </template>

            <div
              v-if="invitations.length"
            >
              <!-- Recieved Invitation - Lawyer/Company/Group -->
              <div v-if="account.accountType !== 'individual' && account.accountType !== 'company'">
                <v-card-subtitle v-if="companyInvitations.length">
                  {{ $t('invitations|company') }}
                </v-card-subtitle>
                <div
                  v-for="companyInvitation in companyInvitations"
                  :key="companyInvitation._id"
                >
                  <v-card
                    v-if="account.companyId"
                    flat
                  >
                    <v-card-text>
                      <InvitationsCard
                        v-if="companyInvitation.status === 'pending' && companyInvitation.sharedWithType === 'company'"
                        :invitation="companyInvitation"
                        @open-invitations-info-dialog="$emit('open-invitations-info-dialog')"
                        @accept-invitation="acceptInvitation"
                        @reject-invitation="rejectInvitation"
                      />
                      <AcceptedInvitationCard
                        v-if="companyInvitation.status === 'accepted'"
                        :invitation="companyInvitation"
                        @remove-accepted-invitation="removeActualInvitation"
                      />
                      <RejectedInvitationCard
                        v-if="companyInvitation.status === 'declined'"
                        :invitation="companyInvitation"
                        @remove-rejected-invitation="removeActualInvitation"
                      />
                    </v-card-text>
                  </v-card>
                </div>

                <v-card-subtitle v-if="groupInvitations.length">
                  {{ $t('invitations|group') }}
                </v-card-subtitle>
                <div
                  v-for="groupInvitation in groupInvitations"
                  :key="groupInvitation._id"
                >
                  <v-card
                    v-if="groupAccess(groupInvitation)"
                    flat
                  >
                    <v-card-text>
                      <InvitationsCard
                        v-if="groupInvitation.status === 'pending' && groupInvitation.sharedWithType === 'groups'"
                        :invitation="groupInvitation"
                        @open-invitations-info-dialog="$emit('open-invitations-info-dialog')"
                        @accept-invitation="acceptInvitation"
                        @reject-invitation="rejectInvitation"
                      />
                      <AcceptedInvitationCard
                        v-if="groupInvitation.status === 'accepted'"
                        :invitation="groupInvitation"
                        @remove-accepted-invitation="removeActualInvitation"
                      />
                      <RejectedInvitationCard
                        v-if="groupInvitation.status === 'declined'"
                        :invitation="groupInvitation"
                        @remove-rejected-invitation="removeActualInvitation"
                      />
                    </v-card-text>
                  </v-card>
                </div>

                <v-card-subtitle v-if="accountInvitations.length">
                  {{ $t('invitations|personal') }}
                </v-card-subtitle>
                <div
                  v-for="accountInvitation in accountInvitations"
                  :key="accountInvitation._id"
                >
                  <v-card
                    flat
                  >
                    <v-card-text>
                      <InvitationsCard
                        v-if="accountInvitation.status === 'pending' && accountInvitation.sharedWithType === 'account'"
                        :invitation="accountInvitation"
                        @open-invitations-info-dialog="emitFurther"
                        @accept-invitation="acceptInvitation"
                        @reject-invitation="rejectInvitation"
                      />
                      <AcceptedInvitationCard
                        v-if="accountInvitation.status === 'accepted'"
                        :invitation="accountInvitation"
                        @remove-accepted-invitation="removeActualInvitation"
                      />
                      <RejectedInvitationCard
                        v-if="accountInvitation.status === 'declined'"
                        :invitation="accountInvitation"
                        @remove-rejected-invitation="removeActualInvitation"
                      />
                    </v-card-text>
                  </v-card>
                </div>
              </div>

              <!-- Client -> Individual or Company -->
              <div v-else>
                <div
                  v-for="invitation in invitations"
                  :key="invitation._id"
                >
                  <v-card
                    flat
                  >
                    <v-card-text>
                      <InvitationsCard
                        v-if="invitation.status === 'pending'"
                        :invitation="invitation"
                        @open-invitations-info-dialog="$emit('open-invitations-info-dialog')"
                        @accept-invitation="acceptInvitation"
                        @reject-invitation="rejectInvitation"
                      />
                      <AcceptedInvitationCard
                        v-if="invitation.status === 'accepted'"
                        :invitation="invitation"
                        @remove-accepted-invitation="removeActualInvitation"
                      />
                      <RejectedInvitationCard
                        v-if="invitation.status === 'declined'"
                        :invitation="invitation"
                        @remove-rejected-invitation="removeActualInvitation"
                      />
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-center"
            >
              <span
                class="ml-2"
                style="font-size: 16px"
              >
                {{ $t('notifications|no_current_invitations') }}
              </span>
            </div>
          </v-list-group>

          <!-- INVITATIONS SENT -->
          <v-list-group
            :value="!!allInvitationsSent.length"
            prepend-icon="mdi-send"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t('invitations|invitations_sent') }}</v-list-item-title>
            </template>

            <!-- Invitation Sent - Client -> Individual or Company -->
            <div v-if="account.accountType === 'company' || account.accountType === 'individual'">
              <div v-if="accountInvitationsSent.length">
                <div
                  v-for="invitation in accountInvitationsSent"
                  :key="invitation._id"
                >
                  <v-card
                    flat
                  >
                    <v-card-text class="px-4">
                      <InvitationsSentCard
                        v-if="invitation.status === 'pending'"
                        :invitation="invitation"
                        @open-invitations-info-dialog="$emit('open-invitations-info-dialog')"
                        @cancel-invitation="clientIsCancelingInvitaion(invitation)"
                      />
                      <AcceptedInvitationCard
                        v-if="invitation.status === 'accepted'"
                        :invitation="invitation"
                        @remove-accepted-invitation="removeActualInvitation"
                      />
                      <RejectedInvitationSentCard
                        v-if="invitation.status === 'declined'"
                        :invitation="invitation"
                        @remove-rejected-invitation-sent="removeInvitationSentClient(invitation, account.myProfessionals.pending)"
                      />
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-center"
              >
                <span
                  class="ml-2"
                  style="font-size: 16px"
                >
                  {{ $t('notifications|no_current_invitations') }}
                </span>
              </div>
            </div>

            <!-- Lawyer/Company/Group -->
            <div v-else>
              <v-list-group
                :value="!!accountInvitationsSent.length"
                no-action
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('mixed|personal') }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <div v-if="accountInvitationsSent.length">
                  <div
                    v-for="invitation in accountInvitationsSent"
                    :key="invitation._id"
                  >
                    <v-card
                      flat
                    >
                      <v-card-text class="px-4">
                        <InvitationsSentCard
                          v-if="invitation.status === 'pending'"
                          :invitation="invitation"
                          @open-invitations-info-dialog="$emit('open-invitations-info-dialog')"
                          @cancel-invitation="lawyerIsCancelingInvitation"
                        />
                        <RejectedInvitationCard
                          v-if="invitation.status === 'declined'"
                          :invitation="invitation"
                          @remove-rejected-invitation="removeInvitationSent"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-center"
                >
                  <span
                    class="ml-2"
                    style="font-size: 16px"
                  >
                    {{ $t('notifications|no_current_invitations') }}
                  </span>
                </div>
              </v-list-group>

              <v-list-group
                :value="!!company && company.invitationsSent && companyInvitationsSent.length"
                no-action
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ company.companyData.companyName ? company.companyData.companyName : company.companyData.email }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <div v-if="companyInvitationsSent.length">
                  <div
                    v-for="invitation in companyInvitationsSent"
                    :key="invitation._id"
                  >
                    <v-card
                      flat
                    >
                      <v-card-text class="pt-4 px-8">
                        <InvitationsSentCard
                          v-if="invitation.status === 'pending'"
                          :invitation="invitation"
                          @open-invitations-info-dialog="$emit('open-invitations-info-dialog')"
                          @cancel-invitation="lawyerIsCancelingInvitation"
                        />
                        <RejectedInvitationSentCard
                          v-if="invitation.status === 'declined'"
                          :invitation="invitation"
                          @remove-rejected-invitation-sent="removeInvitationSent"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-center"
                >
                  <span
                    class="ml-2"
                    style="font-size: 16px"
                  >
                    {{ $t('notifications|no_current_invitations') }}
                  </span>
                </div>
              </v-list-group>

              <v-list-group
                :value="!!groupInvitationsSent.length"
                no-action
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('groups|group') }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <div v-if="groupInvitationsSent.length">
                  <div
                    v-for="invitation in groupInvitationsSent"
                    :key="invitation._id"
                  >
                    <v-card
                      flat
                    >
                      <v-card-text>
                        <InvitationsSentCard
                          v-if="invitation.status === 'pending'"
                          :invitation="invitation"
                          @open-invitations-info-dialog="$emit('open-invitations-info-dialog')"
                          @cancel-invitation="lawyerIsCancelingInvitation"
                        />
                        <RejectedInvitationSentCard
                          v-if="invitation.status === 'declined'"
                          :invitation="invitation"
                          @remove-rejected-invitation-sent="removeInvitationSent"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-center"
                >
                  <span
                    class="ml-2"
                    style="font-size: 16px"
                  >
                    {{ $t('notifications|no_current_invitations') }}
                  </span>
                </div>
              </v-list-group>
            </div>
          </v-list-group>
        </v-list>
      </v-col>

      <!-- DOCUMENT NOTIFICATIONS -->
      <v-col
        v-if="isDocumentUpdatesTab"
        cols="8"
      >
        <div v-if="filteredDocumentsNotifications.length">
          <div
            v-for="n in filteredDocumentsNotifications"
            :key="n._id"
            class="mt-8"
          >
            <NotificationsCard
              v-if="n.object_type === 'Documents'"
              :notification="n"
              @delete-notification="deleteNotification"
              @open-document="openDocument"
              @mark-as-read="onMarkAsRead"
              @navigate-to-item="onNavigateToItem"
              @open-info-dialog="$emit('open-info-dialog', n)"
            />
          </div>
        </div>
        <div
          v-else
          class="center"
        >
          <v-icon
            color="primary"
          >
            mdi-bell-outline
          </v-icon>
          <span class="ml-2">{{ $t('notifications|no_current_doc_updates') }}</span>
        </div>
      </v-col>

      <!-- CASES/QUESTIONNIRE NOTIFICATIONS -->
      <v-col
        v-if="isCasesUpdatesTab"
        cols="8"
      >
        <div v-if="filteredCasesNotifications.length">
          <div
            v-for="n in filteredCasesNotifications"
            :key="n._id"
            class="mt-8"
          >
            <NotificationsCard
              v-if="n.object_type === 'Case'"
              :notification="n"
              @delete-notification="deleteNotification"
              @mark-as-read="onMarkAsRead"
              @navigate-to-item="onNavigateToItem"
              @open-info-dialog="$emit('open-info-dialog', n)"
            />
          </div>
        </div>
        <div
          v-else
          class="center"
        >
          <v-icon
            color="primary"
          >
            mdi-bell-outline
          </v-icon>
          <span class="ml-2">{{ $t('notifications|no_current_case_updates') }}</span>
        </div>
      </v-col>

      <!-- CLIENTS NOTIFICATIONS -->
      <v-col
        v-if="isClientUpdatesTab"
        cols="8"
      >
        <div v-if="filteredClientsNotifications.length">
          <div
            v-for="n in filteredClientsNotifications"
            :key="n._id"
            class="mt-8"
          >
            <NotificationsCard
              v-if="n.object_type === 'Client'"
              :notification="n"
              @mark-as-read="onMarkAsRead"
              @navigate-to-item="onNavigateToItem"
              @delete-notification="deleteNotification"
            />
          </div>
        </div>
        <div
          v-else
          class="center"
        >
          <v-icon
            color="primary"
          >
            mdi-bell-outline
          </v-icon>
          <span class="ml-2">{{ $t('notifications|no_current_client_updates') }}</span>
        </div>
      </v-col>

      <!-- TEMPLATES NOTIFICATIONS -->
      <v-col
        v-if="isTemplatesUpdatesTab"
        cols="8"
      >
        <div v-if="filteredTemplatesNotifications.length">
          <div
            v-for="n in filteredTemplatesNotifications"
            :key="n._id"
            class="mt-8"
          >
            <NotificationsCard
              v-if="n.object_type === 'Template'"
              :notification="n"
              @mark-as-read="onMarkAsRead"
              @navigate-to-item="onNavigateToItem"
              @delete-notification="deleteNotification"
            />
          </div>
        </div>
        <div
          v-else
          class="center"
        >
          <v-icon
            color="primary"
          >
            mdi-bell-outline
          </v-icon>
          <span class="ml-2">{{ $t('notifications|no_current_template_updates') }}</span>
        </div>
      </v-col>
    </v-row>
    <FileView
      ref="fileView"
    />
  </v-card>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import generalMixin from '@/utils/generalMixin.js'
import InvitationsCard from './InvitationsCard.vue'
import AcceptedInvitationCard from './AcceptedInvitationCard.vue'
import RejectedInvitationCard from './RejectedInvitationCard.vue'
import FileView from '../../components/dialogs/FileView.vue'
import NotificationsCard from './NotificationsCard.vue'
import InvitationsSentCard from './InvitationsSentCard.vue'
import RejectedInvitationSentCard from './RejectedInvitationSentCard.vue'

export default {
  components: {
    InvitationsCard,
    AcceptedInvitationCard,
    RejectedInvitationCard,
    FileView,
    NotificationsCard,
    InvitationsSentCard,
    RejectedInvitationSentCard
  },
  mixins: [generalMixin],
  props: {
    isAllNotificationsTab: {
      type: Boolean,
      default: false
    },
    isInvitationsTab: {
      type: Boolean,
      default: false
    },
    isDocumentUpdatesTab: {
      type: Boolean,
      default: false
    },
    isCasesUpdatesTab: {
      type: Boolean,
      default: false
    },
    isClientUpdatesTab: {
      type: Boolean,
      default: false
    },
    isTemplatesUpdatesTab: {
      type: Boolean,
      default: false
    },
    isMembersUpdatesTab: {
      type: Boolean,
      default: false
    },
    allMyNotifications: {
      type: Array,
      default: () => ([])
    },
    invitations: {
      type: Array,
      default: () => ([])
    },
    filteredDocumentsNotifications: {
      type: Array,
      default: () => ([])
    },
    filteredCasesNotifications: {
      type: Array,
      default: () => ([])
    },
    filteredClientsNotifications: {
      type: Array,
      default: () => ([])
    },
    filteredTemplatesNotifications: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    incommingNotification: {},
    isVisible: true,
    infoDialog: false
  }),
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients,
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments
    }),
    accountInvitations () {
      return this.account.invitations.slice().reverse() || []
    },
    companyInvitations () {
      if (this.company) {
        return this.company.invitations.slice().reverse()
      } else return []
    },
    allInvitations () {
      return [...this.accountInvitations, ...this.companyInvitations, ...this.groupInvitations]
    },
    groupInvitations () {
      let allGroupInvitations = []
      if (this.company) {
        this.company.groups.forEach(group => {
          group.invitations.forEach(inv => {
            allGroupInvitations.push(inv)
          })
        })
      }
      return allGroupInvitations.slice().reverse()
    },
    accountInvitationsSent () {
      return this.account.invitationsSent.slice().reverse() || []
    },
    companyInvitationsSent () {
      if (this.company && this.company.invitationsSent) {
        return this.company.invitationsSent.slice().reverse()
      } else return []
    },
    groupInvitationsSent () {
      let allGroupInvitationsSent = []
      if (this.company) {
        this.company.groups.forEach(group => {
          if (group.invitationsSent && group.invitationsSent.length) {
            group.invitationsSent.forEach(inv => {
              allGroupInvitationsSent.push(inv)
            })
          }
        })
      }
      return allGroupInvitationsSent.slice().reverse()
    },
    allInvitationsSent () {
      return [...this.accountInvitationsSent, ...this.companyInvitationsSent, ...this.groupInvitationsSent]
    },
    allNotifications () {
      return this.allMyNotifications.slice().reverse()
    }
  },
  created () {
    EventBus.$on('incomming_notification', this.onHandleNotification)
  },
  beforeDestroy () {
    EventBus.$off('incomming_notification', this.onHandleNotification)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      deleteMyNotification: 'account/deleteMyNotification',
      fetchAccount: 'account/fetchAccount',
      getDocument: 'documents/getDocument',
      acceptInvitationFromProfessional: 'account/acceptInvitationFromProfessional',
      acceptInvitationFromClient: 'clients/acceptInvitationFromClient',
      acceptCompanyMembership: 'account/acceptCompanyMembership',
      rejectActualInvitation: 'account/rejectActualInvitation',
      rejectCompanyOrGroupInvitation: 'company/rejectCompanyOrGroupInvitation',
      rejectLawFirmInvitation: 'account/rejectLawFirmInvitation',
      removeInvitation: 'account/removeInvitation',
      removeCompanyOrGroupInvitation: 'company/removeCompanyOrGroupInvitation',
      deleteTheDeclinedInvitation: 'account/deleteTheDeclinedInvitation',
      deleteClientAction: 'clients/deleteClient',
      cancelInvitationFromClientAccount: 'account/cancelInvitationFromClientAccount',
      cancelInvitationBackend: 'account/cancelInvitation'
    }),
    emitFurther (input) {
      this.$emit('open-invitations-info-dialog', input)
    },
    groupAccess (invitation) {
      if (this.account.companyId && this.company) {
        const group = this.company.groups.find(gr => gr._id === invitation.sharedWith)
        if (group) {
          const access = group.groupMembers.find(member => member._id === this.account._id)
          if (access) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    sortByUpdated (item) {
      const result = moment.utc(item)
      return result
    },
    onHandleNotification (notification) {
      if (notification.object_type === 'Documents') {
        const doc = this.documents.filter(d => d._id === notification.relevant_object) ||
        this.companyDocuments.filter(d => d._id === notification.relevant_object) ||
        this.groupDocuments.filter(d => d._id === notification.relevant_object)
        return doc
      }
    },
    onMarkAsRead (notification) {
      this.$emit('mark-as-read', notification)
    },
    onNavigateToItem (notification) {
      if (notification.object_type === 'Case') {
        const caseId = notification.relevant_object
        const actualCase = this.cases.find(_c => _c._id === caseId) || this.companyCases.find(_c => _c._id === caseId) || this.groupCases.find(_c => _c._id === caseId)
        if (actualCase) this.$router.push({ path: `/cases/${actualCase._id}/${actualCase.workbenches[0]._id}` })
        else {
          this.addToast({
            title: this.$t('cases|no_longer_available'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
      if (notification.object_type === 'Client') {
        const clientId = notification.relevant_object
        const actualClient = this.clients.find(_cl => _cl._id === clientId) || this.companyClients.find(_cl => _cl._id === clientId) || this.groupClients.find(_cl => _cl._id === clientId)
        if (actualClient) this.$router.push({ path: `/clients/${actualClient._id}` })
        else {
          this.addToast({
            title: this.$t('clients|no_longer_available'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
      if (notification.object_type === 'Template') {
        const tempId = notification.relevant_object
        const actualTemp = this.templates.find(_temp => _temp._id === tempId) || this.companyTemplates.find(_ctemp => _ctemp._id === tempId) || this.groupTemplates.find(_grtemp => _grtemp._id === tempId)
        if (actualTemp) this.$router.push({ path: `/templates/${actualTemp._id}` })
        else {
          this.addToast({
            title: this.$t('templates|no_longer_available'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
      if (notification.object_type === 'Documents') {
        this.openDocument(notification.relevant_object)
      }
    },
    async deleteNotification (notification) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('warning|you_want_to_delete_this_notification')
      })
      if (!res) return
      const payload = {
        myNotifications: notification
      }
      try {
        await this.deleteMyNotification(payload)
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      }
    },
    async acceptInvitation (invitation) {
      const _id = invitation.senderAccountId
      const senderType = invitation.senderType
      if (this.account && this.account.accountType === 'lawyer' && senderType !== 'law-firm') {
        const payload = {
          realClient: true,
          acceptClientRequest: true,
          clientAccount: _id,
          status: 'accepted',
          invitationId: invitation._id,
          sharedWith: invitation.sharedWith,
          sharedWithType: invitation.sharedWithType
        }
        try {
          await this.acceptInvitationFromClient({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      }
      if (this.account && this.account.accountType === 'individual' && senderType !== 'law-firm') {
        const payload = {
          accountType: 'individual',
          sharedWith: invitation.sharedWith,
          sharedWithType: invitation.sharedWithType,
          pinned: [],
          documents: [],
          dummyClient: invitation.dummyClient,
          clientData: {
            given_names: this.account.accountData.firstName,
            surname: this.account.accountData.lastName,
            email: this.account.email,
            nationality: this.account.accountData.nationality,
            place_of_birth: this.account.accountData.place_of_birth,
            date_of_birth: this.account.accountData.date_of_birth,
            address: this.account.accountData.address,
            city: this.account.accountData.city,
            postal_code: this.account.accountData.postal_code
          },
          status: 'accepted',
          invitationId: invitation._id,
          clientType: this.account.accountType,
          realClient: true,
          clientAccount: this.account._id,
          invitation: invitation
        }
        try {
          await this.acceptInvitationFromProfessional({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      }
      if (this.account && this.account.accountType === 'company' && senderType !== 'law-firm') {
        const payload = {
          accountType: 'company',
          sharedWith: invitation.sharedWith,
          sharedWithType: invitation.sharedWithType,
          pinned: [],
          documents: [],
          dummyClient: invitation.dummyClient,
          clientData: this.account.accountData,
          status: 'accepted',
          invitationId: invitation._id,
          clientType: this.account.accountType,
          realClient: true,
          clientAccount: this.account._id
        }
        try {
          await this.acceptInvitationFromProfessional({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      }
      if (this.account && senderType === 'law-firm') {
        try {
          const payload = {
            invitation: invitation
          }
          await this.acceptCompanyMembership({ payload })
        } catch (error) {
          console.error(error, 'ERROR')
        }
      }
    },
    async rejectInvitation (invitation) {
      let _id
      let payload
      try {
        if (invitation.senderType === 'law-firm') {
          _id = this.account._id
          payload = {
            invitation: invitation,
            accountType: this.account.accountType
          }
          await this.rejectLawFirmInvitation({ _id, payload })
        } else {
          if (this.account.accountType === 'individual' || this.account.accountType === 'company') {
            _id = this.account._id
            payload = {
              invitation,
              clientAccount: true,
              accountType: this.account.accountType
            }
            await this.rejectActualInvitation({ _id, payload })
          } else {
            if (invitation.sharedWithType === 'account') {
              _id = this.account._id
              payload = {
                invitation,
                lawyerAccount: true,
                accountType: this.account.accountType
              }
              await this.rejectActualInvitation({ _id, payload })
            }
            if (invitation.sharedWithType === 'groups') {
              _id = invitation.sharedWith
              payload = {
                invitation,
                group: true
              }
              this.rejectCompanyOrGroupInvitation({ _id, payload })
            }
            if (invitation.sharedWithType === 'company') {
              _id = invitation.sharedWith
              payload = {
                invitation,
                company: true
              }
              this.rejectCompanyOrGroupInvitation({ _id, payload })
            }
          }
        }
      } catch (error) {
        console.error(error, 'error')
      }
    },
    async removeActualInvitation (invitation) {
      let _id
      let payload
      try {
        if (this.account.accountType === 'individual' || this.account.accountType === 'company') {
          _id = this.account._id
          payload = {
            invitation,
            account: true
          }
          await this.removeInvitation({ _id, payload })
        } else {
          if (invitation.sharedWithType === 'account') {
            _id = this.account._id
            payload = {
              invitation,
              account: true
            }
            await this.removeInvitation({ _id, payload })
          }
          // if the invitation is send by the client(client types are individual or company) to a group
          if ((invitation.senderType === 'individual' || invitation.senderType === 'company') && invitation.sharedWithType === 'groups') {
            _id = invitation.sharedWith
            payload = {
              invitation,
              group: true
            }
            this.removeCompanyOrGroupInvitation({ _id, payload })
          }
          // if the invitation is send by the client(clients types are individual or company) to a company
          if ((invitation.senderType === 'individual' || invitation.senderType === 'company') && invitation.sharedWithType === 'company') {
            _id = invitation.sharedWith
            payload = {
              invitation,
              company: true
            }
            this.removeCompanyOrGroupInvitation({ _id, payload })
          }
        }
        this.addInfoToast()
      } catch (error) {
        console.error(error, 'error')
      }
    },
    addInfoToast () {
      this.addToast({
        title: this.$t('invitations|successfully_removed'),
        color: 'white',
        snackbarColor: 'success'
      })
    },
    async removeInvitationSentClient (invitation, pendingProfessionalsArray) {
      console.log(invitation, 'remove actual inv sent TO DO')
      const decliner = pendingProfessionalsArray.find(pp => pp.item_id._id === invitation.recipientAccountId)
      const _id = this.account._id
      const payload = {
        professionalId: decliner.item_id._id,
        deleteProfessional: true
      }
      try {
        await this.deleteTheDeclinedInvitation({ _id, payload })
        this.addToast({
          title: this.$t('message|delete_declined_invitation_success'),
          snackbarColor: 'success',
          color: 'white'
        })
      } catch (error) {
        console.error('error', error)
      }
    },
    async removeInvitationSent (invitation) {
      let clientToBeDeleted = null
      let tab = null
      if (invitation && invitation.sharedWithType === 'accounts') {
        clientToBeDeleted = this.clients.find(cl => cl._id === invitation.dummyClient)
        tab = {
          isMyTab: true
        }
      } else if (invitation && invitation.sharedWithType === 'company') {
        clientToBeDeleted = this.companyClients.find(cl => cl._id === invitation.dummyClient)
        tab = {
          isCompanyTab: true,
          companyId: this.company._id
        }
      } else if (invitation && invitation.sharedWithType === 'groups') {
        clientToBeDeleted = this.groupClients.find(cl => cl._id === invitation.dummyClient)
        tab = {
          isGroupTab: true,
          groupId: invitation.sharedWith
        }
      }
      await this.deleteClient(clientToBeDeleted, tab)
    },
    async deleteClient (client, tab) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      let payload
      if (tab.isMyTab) {
        payload = {
          isMyTab: true
        }
      }
      if (tab.isCompanyTab) {
        payload = {
          isCompanyTab: true,
          companyId: tab.companyId
        }
      }
      if (tab.isGroupTab) {
        payload = {
          isGroupTab: true,
          groupId: tab.groupId
        }
      }
      try {
        await this.deleteClientAction({ _id: client._id, payload })
      } catch (e) {
        console.error(e, 'error in delete notification and dummy client in notifications section')
      }
    },
    async clientIsCancelingInvitaion (invitation) {
      const _id = this.account._id
      let professional = null
      if (invitation && invitation.sharedWithType === 'account') professional = this.account.myProfessionals.pending.find(pro => pro.item_id.email)
      if (invitation && invitation.sharedWithType === 'company') professional = this.account.myProfessionals.pending.find(pro => pro.item_id.companyData.email)
      if (invitation && invitation.sharedWithType === 'groups') professional = this.account.myProfessionals.pending.find(pro => pro.item_id.groupEmail)
      const res = await this.$dialog.confirm({
        text: this.$t('warning|you_want_to_cancel_invitation'),
        title: this.$t('common|warning')
      })
      if (!res) return
      const payload = {
        invitationSent: invitation,
        professional: professional
      }
      try {
        await this.cancelInvitationFromClientAccount({ _id, payload })
      } catch (error) {
        console.error(error)
        this.addToast({
          title: 'Canceling the invitation was not successful',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async lawyerIsCancelingInvitation (invitationSent) {
      const _id = this.account._id
      const res = await this.$dialog.confirm({
        text: this.$t('warning|you_want_to_cancel_invitation'),
        title: this.$t('common|warning')
      })
      if (!res) return
      const payload = invitationSent
      try {
        await this.cancelInvitationBackend({ _id, payload })
      } catch (error) {
        console.error(error)
        this.addToast({
          title: 'Canceling the invitation was not successful',
          color: 'white',
          snackbarColor: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
