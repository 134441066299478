<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :key="componentKey"
      :elevation="hover ? 6 : 2"
      rounded
      class="my-4 d-flex"
    >
      <v-row no-gutters>
        <v-col md="1">
          <v-card
            class="rounded-r-0"
            flat
            height="100%"
            :color="getColor(notification)"
          >
            <v-card-text
              class="text-center"
            >
              <v-icon
                v-if="notification && notification.status === 'success'"
                color="white"
                size="30"
                class="centered-icon"
              >
                mdi-check-circle-outline
              </v-icon>
              <v-icon
                v-if="notification && notification.status === 'error'"
                color="white"
                size="30"
                class="centered-icon"
              >
                mdi-alert-octagon-outline
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="11">
          <v-card
            dark
            flat
            height="100%"
            class="rounded-l-0"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="position: absolute; top: 2px; right: 8px;"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-if="notification && notification.status === 'success'"
                  @click="$emit('navigate-to-item', notification)"
                >
                  <v-icon
                    class="mt-1 mr-2"
                  >
                    {{ openIcon }}
                  </v-icon>
                  <v-list-item-title>{{ $t('actions|open') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="notification && notification.status === 'error'"
                  @click.stop="$emit('open-info-dialog', notification)"
                >
                  <v-icon
                    class="mt-1 mr-2"
                  >
                    mdi-information-variant
                  </v-icon>
                  <v-list-item-title>{{ $t('actions|show_more') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="notification.status_read"
                  @click.stop="$emit('mark-as-read', notification)"
                >
                  <v-icon
                    :disabled="notification.status_read"
                    class="mt-1 mr-2"
                  >
                    mdi-read
                  </v-icon>
                  <v-list-item-title>{{ $t('notifications|mark_read') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click.stop="$emit('delete-notification', notification)"
                >
                  <v-icon
                    class="mt-1 mr-2"
                  >
                    mdi-delete-outline
                  </v-icon>
                  <v-list-item-title>{{ $t('notifications|delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card-text
              class="black--text mt-1 pr-7"
            >
              {{ notification.content.translation ? notification.content.translation : notification.content }}
            </v-card-text>
            <div
              style="font-size: 11px;"
              class="ml-4 mb-2 black--text"
            >
              {{ revertDate(notification.timestamp) }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiArrowTopRight } from '@mdi/js'

export default {
  components: {},
  props: {
    notification: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    openIcon: mdiArrowTopRight,
    componentKey: 1
  }),
  mounted () {
    window.addEventListener('pref-language-changed', (event) => {
      this.componentKey++
    })
  },
  methods: {
    getColor (notification) {
      if (notification && notification.status_read) return 'grey'
      else if (notification && notification.status === 'success') return 'success'
      else if (notification && notification.status === 'error') return 'error'
    },
    revertDate (date) {
      const splittedTimeAndDate = date.split(' ')
      const onlyDate = splittedTimeAndDate[0].split('/')
      const returnDate = onlyDate[2] + '/' + onlyDate[1] + '/' + onlyDate[0] + ' ' + splittedTimeAndDate[1]
      return returnDate
    }
  }
}
</script>

<style scoped>
.centered-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
