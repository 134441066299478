<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      rounded
      height="100%"
      class="d-flex"
    >
      <v-row
        no-gutters
      >
        <v-col cols="1">
          <v-card
            class="rounded-r-0"
            flat
            height="100%"
            color="info"
          >
            <div
              class="center"
            >
              <v-icon
                color="white"
                size="30"
                @click="$emit('open-invitations-info-dialog')"
              >
                mdi-information-variant
              </v-icon>
            </div>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card
            flat
            height="100%"
            class="rounded-l-0"
          >
            <!-- <v-card-text
              class="black--text mt-n2"
            >
              {{ invitation.message }}
            </v-card-text> -->
            <v-card-text
              class="black--text"
            >
              <span
                class="font-weight-light"
                style="font-size: 14px"
              >
                <span class="font-weight-bold">{{ $t('clients|e_mail') }}: </span>
                {{ invitation.recipientEmail }}
              </span>
            </v-card-text>
            <v-card-text class="black--text mt-n9">
              <span
                class="font-weight-light"
                style="font-size: 14px"
              >
                <span class="font-weight-bold">{{ $t('common|sent_on') }}: </span>
                {{ sortByUpdated(invitation.createdAt) }}
              </span>
            </v-card-text>
            <v-card-text
              class="black--text mt-n9"
            >
              <span
                class="font-weight-light"
                style="font-size: 14px;"
              >
                <span class="font-weight-bold">{{ $t('cases|message') }}:</span>
                {{ invitation.additionalMessage ? invitation.additionalMessage : '' }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="3"
        >
          <div
            style="position: relative; top: 36%;"
          >
            <div>
              <v-btn
                rounded
                outlined
                color="primary"
                x-small
                dense
                @click="$emit('cancel-invitation', invitation)"
              >
                Cancel invitation
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    invitation: {
      type: Object,
      default: null
    }
  },
  methods: {
    sortByUpdated (item) {
      const result = moment.utc(item)
      return result
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
