<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      rounded
      height="100%"
      class="d-flex"
    >
      <v-row no-gutters>
        <v-col cols="1">
          <v-card
            class="rounded-r-0"
            flat
            height="100%"
            color="info"
          >
            <div
              class="center"
            >
              <v-icon
                color="white"
                size="30"
                @click="$emit('open-invitations-info-dialog', 'law-firm')"
              >
                mdi-information-variant
              </v-icon>
            </div>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card
            dark
            flat
            height="100%"
            class="rounded-l-0"
          >
            <v-card-text
              class="black--text mt-n2"
            >
              {{ invitation.message }}
            </v-card-text>
            <v-card-text
              class="black--text mt-n8"
            >
              <span
                v-if="invitation.senderType === 'law-firm'"
                class="font-weight-light"
                style="font-size: 14px"
              >
                <span class="font-weight-bold">{{ $t('common|law_firm') }}: </span>
                {{ invitation.senderEmail }}
              </span>
              <span
                v-else
                class="font-weight-light"
                style="font-size: 14px"
              >
                <span class="font-weight-bold">{{ $t('clients|e_mail') }}: </span>
                {{ invitation.senderEmail }}
              </span>
            </v-card-text>
            <v-card-text class="black--text mt-n9">
              <span
                class="font-weight-light"
                style="font-size: 14px"
              >
                <span class="font-weight-bold">{{ $t('common|sent_on') }}: </span>
                {{ sortByUpdated(invitation.createdAt) }}
              </span>
            </v-card-text>
            <v-card-text
              class="black--text mt-n9"
            >
              <span
                v-if="invitation.senderType !== 'law-firm'"
                class="font-weight-light"
                style="font-size: 14px;"
              >
                <span class="font-weight-bold">{{ $t('cases|message') }}:</span>
                {{ invitation.additionalMessage ? invitation.additionalMessage : '' }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <div
            style="position: relative; top: 30%;"
          >
            <div>
              <v-btn
                rounded
                outlined
                color="primary"
                x-small
                dense
                @click="$emit('accept-invitation', invitation)"
              >
                {{ $t('common|accept') }}
              </v-btn>
            </div>
            <div>
              <v-btn
                rounded
                text
                color="primary"
                x-small
                dense
                class="mt-4"
                @click="$emit('reject-invitation', invitation)"
              >
                {{ $t('common|reject') }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    invitation: {
      type: Object,
      default: null
    }
  },
  methods: {
    sortByUpdated (item) {
      const result = moment.utc(item)
      return result
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
